import React, {useEffect, useState} from 'react';
import {
    Button,
    Checkbox,
    Container,
    Group,
    NumberInput,
    Stack,
    TextInput,
    Text
} from "@mantine/core";
import {useForm} from '@mantine/form';
import {TCastleQuest} from "./types";

const url = process.env.REACT_APP_PERCIVAL_SERVER_URL || ""

function App() {

    const form = useForm({
        mode: 'uncontrolled',
        initialValues: {
            name: 'Quest name',
            description: 'Quest description',
            minLevel: 1,
            baseExp: 250,
            isRoyal: false,
            baseCompletionSec: 15,
            timeIncrement: 1.5,
            partCount: 1,
            goldReward: 50,
            goldRewardIncrement: 1.5
        },
    });

    const [castleQuests, setCastleQuests] = useState<TCastleQuest[]>([]);

    useEffect(() => {
        fetchCastles()
    }, [])

    async function fetchCastles() {
        const r = await fetch(url + '/admin/castlequests');
        if (r.ok) {
            const collection = await r.json();
            setCastleQuests(collection.data)
        }
    }

    async function submitCastleQuest(data: unknown) {
        const res = await fetch(url + '/admin/castlequests',
            {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(data)
            })
        if (res.ok) {
            await fetchCastles()
            form.reset()
        }
    }

    return (
        <Container>
            <Group justify="flex-start" align={"flex-start"}>
                <form onSubmit={form.onSubmit(submitCastleQuest)}>
                    <TextInput
                        withAsterisk
                        label={"name"}
                        key={form.key("Name")}
                        {...form.getInputProps('name')}
                    />
                    <TextInput
                        withAsterisk
                        label={"Description"}
                        key={form.key("description")}
                        {...form.getInputProps('description')}
                    />
                    <NumberInput
                        label={"Min level"}
                        key={form.key("minLevel")}
                        {...form.getInputProps('minLevel')}
                    />
                    <NumberInput
                        label={"baseExp"}
                        key={form.key("baseExp")}
                        {...form.getInputProps('baseExp')}
                    />
                    <Checkbox
                        mt="md"
                        label="isRoyal"
                        key={form.key('isRoyal')}
                        {...form.getInputProps('isRoyal', {type: 'checkbox'})}
                    />
                    <NumberInput
                        label={"baseCompletionSec"}
                        key={form.key("baseCompletionSec")}
                        {...form.getInputProps('baseCompletionSec')}
                    />
                    <NumberInput
                        label={"timeIncrement"}
                        key={form.key("timeIncrement")}
                        {...form.getInputProps('timeIncrement')}
                    />
                    <NumberInput
                        label={"partCount"}
                        key={form.key("partCount")}
                        {...form.getInputProps('partCount')}
                    />
                    <NumberInput
                        label={"goldReward"}
                        key={form.key("goldReward")}
                        {...form.getInputProps('goldReward')}
                    />
                    <NumberInput
                        label={"goldRewardIncrement"}
                        key={form.key("goldRewardIncrement")}
                        {...form.getInputProps('goldRewardIncrement')}
                    />
                    <Group justify="flex-end" mt="md">
                        <Button type="submit">Submit</Button>
                    </Group>
                </form>
                <Stack>
                    {castleQuests.map(e=> <Stack key={e._id}>
                        <Text>{e.name}</Text>
                        <Text>{e.description}</Text>
                    </Stack>)}
                </Stack>
            </Group>
        </Container>
    );
}

export default App;
